import * as React from "react";
import { I18nextProvider } from 'react-i18next';

import Home from "../components/home/home";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import i18n from '../../static/i18n';

const Index = () => {
	React.useEffect(() => {
    if (typeof window !== "undefined") {
      const script = document.createElement("script");
      script.innerHTML = `
        window.pipedriveLeadboosterConfig = {
          base: 'leadbooster-chat.pipedrive.com',
          companyId: 13461417,
          playbookUuid: 'f7ac14db-1cb1-45dd-9558-21eb8641d6ff',
          version: 2
        };
        (function () {
          var w = window;
          if (w.LeadBooster) {
            console.warn('LeadBooster already exists');
          } else {
            w.LeadBooster = {
              q: [],
              on: function (n, h) {
                this.q.push({ t: 'o', n: n, h: h });
              },
              trigger: function (n) {
                this.q.push({ t: 't', n: n });
              },
            };
          }
        })();
      `;
      document.body.appendChild(script);

      const scriptSrc = document.createElement("script");
      scriptSrc.src = "https://leadbooster-chat.pipedrive.com/assets/loader.js";
      scriptSrc.async = true;
      document.body.appendChild(scriptSrc);
    }
  }, []);
	
return(
	<>
		<Seo
			title="Mereos"
			description="Mereos sécurise vos examens à distance et certifie le niveau des candidats. Planifiez jusqu'à 20 000 sessions surveillées en simultané et simplifiez votre processus de recrutement."
		/>
		<Layout>
		<I18nextProvider i18n={i18n} defaultNS={'translation'}>
			<Home />
		</I18nextProvider>
		</Layout>
	</>
);
}

export default Index;
